<template>
  <div>
    <div class="contentsWidth mx-auto mt-4">
      <div class="">
        <p class="title mb-0">選考評価担当割当 - 一覧</p>
        <hr class="title">
      </div>

      <div>
        <b-form-select
        class="year-select"
        :options="yearList"
        v-model="search.year"/>
          年度
      </div>

      <div id="tableWrap" class="contentsWidth mt-2">
        <b-table striped hover
          id=''
          table-class=""
          thead-class="tableHead"
          tbody-tr-class=""
          :items="apptypeList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
        >
          <template v-slot:cell(stage)="row">
            <span v-if="row.item.stage">{{row.item.stage}}次</span>
            <span v-if="!row.item.stage">選考段階の登録がありません</span>
          </template>
          <template v-slot:cell(assign)="row">
            {{row.item.assign}} / {{row.item.app}}
          </template>
          <template v-slot:cell(time)="row">
            <span v-if="row.item.stage">{{row.item.from}} ~ {{row.item.to}}</span>
          </template>
          <template v-slot:cell(btn)="row">
            <b-link
              v-if="row.item.stage"
              :to="'/cms/applications/assign/edit/'+row.item.id">
              編集
            </b-link>
          </template>
        </b-table>
      </div>

      <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
        <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';

export default {
  name: 'CmsApplicationsAssignList',
  data() {
    return {
      year: '',
      search: {
        year: '',
      },
      apptypeList: [],
      yearList: [],
      header: [
        { key: 'name', label: '助成種別', sortable: true },
        { key: 'stage', label: '選考段階', sortable: true },
        { key: 'assign', label: '割当有/申請数', sortable: true },
        { key: 'time', label: '選考評価期間', sortable: true },
        { key: 'btn', label: '', sortable: false },
      ],
    };
  },
  methods: {
    async initFetch() {
      const response = await api.send('/api/cms/applications/assign/list')
        .catch((err) => {
          console.log(err);
        });
      if (!response) {
        await this.alert('データが取得できませんでした。');
        return;
      }
      this.apptypeList = response.data.apptypeList;
      this.yearList = response.data.yearList;
    },
    filtering(lineData, search) {
      return lineData.year === search.year;
    },
  },
  computed: {
  },
  // ロード画面
  async created() {
    const year = moment().format('YYYY');
    this.search.year = Number(year);
    this.$store.dispatch('page/onLoading');
    await this.initFetch();
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  .year-select {
    height: 40px;
    width: 120px;
  }
</style>
